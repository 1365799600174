/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import GuestLayout from './layouts/GuestLayout';

export const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/billing" />,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView')),
  },
  // Billing ----------------------
  {
    path: '/billing',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      // Plans -----------------
      {
        name: 'PlansView',
        exact: true,
        path: '/billing/plans',
        component: lazy(() => import('src/views/billing/plans/PlansView')),
      },
      {
        name: 'NewPlanView',
        exact: true,
        path: '/billing/plans/new',
        component: lazy(() => import('src/views/billing/plans/NewPlanView')),
      },
      {
        name: 'PlansDetailsView',
        exact: true,
        path: '/billing/plans/:id',
        component: lazy(() =>
          import('src/views/billing/plans/PlansDetailsView'),
        ),
      },
      {
        name: 'EditPlanView',
        exact: true,
        path: '/billing/plans/:id/edit',
        component: lazy(() => import('src/views/billing/plans/EditPlanView')),
      },
      // Discounts -----------------
      {
        name: 'DiscountsView',
        exact: true,
        path: '/billing/discounts',
        component: lazy(() =>
          import('src/views/billing/discounts/DiscountsView'),
        ),
      },
      {
        name: 'NewDiscountView',
        exact: true,
        path: '/billing/discounts/new',
        component: lazy(() =>
          import('src/views/billing/discounts/NewDiscountView'),
        ),
      },
      {
        name: 'DiscountsDetailsView',
        exact: true,
        path: '/billing/discounts/:id',
        component: lazy(() =>
          import('src/views/billing/discounts/DiscountsDetailsView'),
        ),
      },
      {
        name: 'EditDiscountView',
        exact: true,
        path: '/billing/discounts/:id/edit',
        component: lazy(() =>
          import('src/views/billing/discounts/EditDiscountView'),
        ),
      },
      // Contracts -------------
      {
        name: 'ContractsView',
        exact: true,
        path: '/billing/contracts',
        component: lazy(() =>
          import('src/views/billing/contracts/ContractsView'),
        ),
      },
      {
        name: 'NewContractView',
        exact: true,
        path: '/billing/contracts/new',
        component: lazy(() =>
          import('src/views/billing/contracts/NewContractView'),
        ),
      },
      {
        name: 'ContractsDetailsView',
        exact: true,
        path: '/billing/contracts/:id',
        component: lazy(() =>
          import('src/views/billing/contracts/ContractsDetailsView'),
        ),
      },
      {
        name: 'EditContractView',
        exact: true,
        path: '/billing/contracts/:id/edit',
        component: lazy(() =>
          import('src/views/billing/contracts/EditContractView'),
        ),
      },
      // Exchange rates -------------------
      {
        name: 'ExchangeView',
        exact: true,
        path: '/billing/exchange',
        component: lazy(() =>
          import('src/views/billing/exchange/ExchangeView'),
        ),
      },
      {
        name: 'NewExchangeView',
        exact: true,
        path: '/billing/exchange/new',
        component: lazy(() =>
          import('src/views/billing/exchange/NewExchangeView'),
        ),
      },
      {
        name: 'EditExchangeView',
        exact: true,
        path: '/billing/exchange/:id/edit',
        component: lazy(() =>
          import('src/views/billing/exchange/EditExchangeView'),
        ),
      },
      {
        name: 'ExchangeDetailsView',
        exact: true,
        path: '/billing/exchange/:id',
        component: lazy(() =>
          import('src/views/billing/exchange/ExchangeDetailsView'),
        ),
      },
      // ARR ------------
      {
        exact: true,
        path: '/billing/annual-recurring-revenue',
        component: lazy(() =>
          import('src/views/billing/annual-recurring-revenue/ARRView'),
        ),
      },
      // Institutions -------------
      {
        name: 'InstitutionsView',
        exact: true,
        path: '/billing/institutions',
        component: lazy(() =>
          import('src/views/billing/institutions/InstitutionsView'),
        ),
      },

      // Base billing route ---------------
      {
        exact: true,
        path: '/billing',
        component: () => <Redirect to="/billing/contracts" />,
      },
    ],
  },

  // Dashboard ------------
  {
    path: '/dashboard',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      // Institutions -------------
      {
        name: 'DashboardInstitutionsView',
        exact: true,
        path: '/dashboard/institutions',
        component: lazy(() =>
          import('src/views/dashboard/institutions/InstitutionsView'),
        ),
      },
      {
        name: 'DashboardInstitutionsDetailsView',
        exact: true,
        path: '/dashboard/institutions/:id',
        component: lazy(() =>
          import('src/views/dashboard/institutions/InstitutionsDetailsView'),
        ),
      },
    ],
  },

  // Guest ------------------------
  {
    path: '/guest',
    layout: GuestLayout,
    routes: [
      // Invoices -----------------
      {
        name: 'InvoicePayment',
        exact: true,
        path: '/guest/invoices/payment',
        component: lazy(() =>
          import('src/views/guest/invoices/InvoicesPayment'),
        ),
      },
    ],
  },
  // Template ---------------------
  {
    path: '/app',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />,
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/management/CustomerListView')),
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() =>
          import('src/views/management/CustomerDetailsView'),
        ),
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/management/CustomerEditView')),
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/management/ProductListView')),
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/management/ProductCreateView')),
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/management/OrderDetailsView')),
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() =>
          import('src/views/management/InvoiceDetailsView'),
        ),
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />,
      },
    ],
  },
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

const Routes = () => renderRoutes(routesConfig);

export default Routes;
