import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Folder as FolderIcon,
  Clipboard as ClipboardIcon,
  DollarSign as DollarSignIcon,
  Repeat as RepeatIcon,
  Briefcase as BriefcaseIcon,
  CreditCard as CreditCardIcon,
  Home as HomeIcon,
} from 'react-feather';

import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: 'Facturación',
    items: [
      // Plans
      {
        title: 'Planes',
        icon: ClipboardIcon,
        href: '/billing/plans',
        items: [
          {
            title: 'Lista de planes',
            href: '/billing/plans',
          },
          {
            title: 'Agregar',
            href: '/billing/plans/new',
          },
        ],
      },
      // Contracts --------------
      {
        title: 'Contratos',
        icon: FolderIcon,
        href: '/billing/contracts',
        items: [
          {
            title: 'Lista de contratos',
            href: '/billing/contracts',
          },
          {
            title: 'Agregar',
            href: '/billing/contracts/new',
          },
        ],
      },
      // Exchange rates -----------
      {
        title: 'Tasa de cambio',
        icon: DollarSignIcon,
        href: '/billing/exchange',
        items: [
          {
            title: 'Lista de tasas',
            href: '/billing/exchange',
          },
          {
            title: 'Agregar',
            href: '/billing/exchange/new',
          },
        ],
      },
      {
        title: 'Facturas',
        icon: CreditCardIcon,
        href: '/billing/invoices',
        items: [
          {
            title: 'Pagar factura',
            href: '/billing/invoices/payment',
          },
        ],
      },
      // Annual Recurring Revenue
      {
        title: 'Ingresos recurrentes anuales',
        icon: RepeatIcon,
        href: '/billing/annual-recurring-revenue',
      },
      // Institutions
      {
        title: 'Instituciones',
        icon: BriefcaseIcon,
        href: '/billing/institutions',
      },
    ],
  },
  {
    subheader: 'Administración',
    // Institutions
    items: [
      {
        title: 'Instituciones',
        icon: HomeIcon,
        href: '/dashboard/institutions',
      },
    ],
  },
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0, onClick }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        onClick={onClick}
      />,
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ openMobile, onMobileClose }) => {
  const classes = useStyles();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!openMobile) {
      onMobileClose();
    }
  }, [openMobile, onMobileClose]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <Avatar alt="User" className={classes.avatar} src={user.photo} />
          </Box>
          <Box mt={2} textAlign="center">
            <Typography variant="h5" color="textPrimary" underline="none">
              {`${user.name} ${user.lastName}`}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
                onClick: onMobileClose,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
