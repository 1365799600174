import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Button,
  Box,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const Fallback = ({ resetErrorBoundary }) => {
  return (
    <Dialog open>
      <Alert severity="error">
        <AlertTitle>Error critico</AlertTitle>
        Ha ocurrido un error inesperado, estamos trabajando para solucionarlo, vuelva a cargar la página más tarde.

        <Box textAlign="right" pt={2}>
          <Button variant="outlined" onClick={resetErrorBoundary}>
            Volver al inicio
          </Button>
        </Box>
      </Alert>
    </Dialog>
  );
};

Fallback.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default Fallback;
