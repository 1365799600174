import { logger } from 'redux-logger';
import { init } from '@rematch/core';
import createRematchPersist from '@rematch/persist';
import localStorage from 'redux-persist/lib/storage';

import { ENABLE_REMATCH_DEBUG } from 'src/config';
import models from 'src/store/models';

const persistPlugin = createRematchPersist({
  version: 1,
  key: 'blended-backoffice',
  storage: localStorage,
  timeout: 10000,
  whitelist: [
    'user',
    'configuration',
  ],
  debug: ENABLE_REMATCH_DEBUG,
});

const configureStore = () => {
  const middlewares = [];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
  }

  const store = init({
    models,
    plugins: [persistPlugin],
    redux: {
      middlewares
    }
  });

  return store;
};

const store = configureStore();

export default store;
