import React from 'react';
import {
  Box,
  CircularProgress,
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import { useStyles } from './styles';

const SlashScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        mb={6}
      >
        <Logo className={classes.logo} />
      </Box>
      <CircularProgress color="primary" />
    </div>
  );
};

export default SlashScreen;
