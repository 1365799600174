import JWT from 'jwt-decode';
import API from './api';

class AuthService extends API {
  constructor() {
    super({ baseURL: process.env.REACT_APP_UIA_URL });

    this.isAuthenticated = false;
  }

  async getUserInformation(uniqueId) {
    return this.axios('GET', `/api/user/basic/${uniqueId}`);
  }

  async loginWithEmailAndPassword(credentials) {
    return this.axios('POST', '/api/public-user/login', credentials);
  }

  async logout() {
    return this.axios('POST', '/api/public-user/logout');
  }

  isValidToken(token) {
    if (!token || typeof token !== 'string') return false;

    const decoded = JWT(token);
    const currentTime = Date.now() / 1000;

    const isValid = decoded.exp > currentTime;

    this.isAuthenticated = isValid;

    return isValid;
  }
}

const authService = new AuthService();

export default authService;
