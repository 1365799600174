import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import NavBar from './NavBar';
import TopBar from './TopBar';

import useStyles from './styles';

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const handleNavOpen = useCallback((force) => () => setMobileNavOpen(force), [setMobileNavOpen]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={handleNavOpen(true)} />
      <NavBar
        onMobileClose={handleNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any
};

export default MainLayout;
