import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const AuthGuard = ({ children }) => {
  const user = useSelector((state) => state.user);

  if (!user.id) {
    return <Redirect to="/login" />;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
