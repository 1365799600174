import update from 'immutability-helper';

const reducers = {
  cleanUser(state) {
    return update(state, { $set: {} });
  },

  setUserMinimumData(state, data) {
    return update(state, { $merge: data });
  },

  setUserData(state, data) {
    return update(state, { $merge: data });
  }
};

export default reducers;
