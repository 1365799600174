import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import DayJSUtils from '@date-io/dayjs';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { getPersistor } from '@rematch/persist';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PersistGate } from 'redux-persist/es/integration/react';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import SplashScreen from 'src/components/SplashScreen';
import ScrollReset from 'src/components/ScrollReset';
import ErrorBoundary from 'src/components/ErrorBoundary';
import useSettings from 'src/hooks/useSettings';
import createTheme from 'src/theme';
import Routes from 'src/Routes';
import store from 'src/store';
import authService from 'src/services/auth';
import { PUBLIC_ROUTES } from './constants';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

const App = () => {
  useStyles();
  const { settings } = useSettings();
  const baseTheme = createTheme(settings);

  const appInit = async () => {
    const pathName = window.location.pathname;
    const { apiKey } = store.getState().user;
    const isValidToken = authService.isValidToken(apiKey);
    const isPublicRoute = PUBLIC_ROUTES.map((publicRoute) =>
      window.location.pathname.includes(publicRoute)
    );

    if (isPublicRoute) return;

    if (!isValidToken) {
      const params = pathName ? `?redirect=${pathName}&rejected=token` : '';
      const redirectURL = `/login${params}`;
      window.location.replace(redirectURL);
      return;
    }

    await store.dispatch.user
      .loginFromSIS(apiKey)
      .catch(() => window.location.replace('/login?rejected=auth_fail'));
  };

  return (
    <ThemeProvider theme={baseTheme}>
      <StylesProvider jss={jss}>
        <PersistGate
          loading={<SplashScreen />}
          persistor={getPersistor()}
          onBeforeLift={appInit}
        >
          <MuiPickersUtilsProvider utils={DayJSUtils}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              maxSnack={1}
              preventDuplicate
            >
              <Router history={history}>
                <ErrorBoundary>
                  <ScrollReset />
                  <GoogleAnalytics />
                  <Routes />
                </ErrorBoundary>
              </Router>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </PersistGate>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
